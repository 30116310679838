/**
 * init plugins
 */
initPlugins=function(){
  //algunos plugins interesa solo inicializarlos en mobile
  if (anchoventana>=md){
      /*animaciones WOW*/
        new WOW().init();

      /* scroll parallax */
        //var s = skrollr.init();          
  }


  /* carrusel swiper */
  // var swiper = new Swiper('.carrusel', {
  //     pagination: '.swiper-pagination',
  //     slidesPerView: 1,
  //     paginationClickable: true,
  //     }
  // });

    

};